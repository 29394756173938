import { fetchApi } from '@/utils/axios';
import URL from '@/api/urlConfig';

/**
 * 获取banner列表
 * @param {*} data.title 标题
 * @param {*} data.type 类型
 * @param {*} data.status 状态
 * @param {*} data.createdAt 更新日期开始时间
 * @param {*} data.endAt 更新日期结束时间
 * @param {*} data.start_time 有效日期开始时间
 * @param {*} data.end_time 有效日期结束时间
 * @param {*} data.showLocation 显示位置
 * @param {*} data.showPort 透出端口
 * @param {*} data.visibleProject 可见项目
 * @param {*} data.sort 排序方式
 * @param {*} data.page
 * @param {*} data.size
 * @returns
 */
export const getBannerList = (data) => fetchApi(URL.GET_BANNER_LIST, data, 'post', 'data', true);

/**
 * 添加banner
 * @param {*} data
 * @returns
 */
export const addBanner = (data) => fetchApi(URL.BANNER_OPERATION, data, 'post', 'data');

/**
 * 获取banner详情
 * @param {*} id
 * @returns
 */
export const getBannerDetail = (id) => fetchApi(`${URL.BANNER_OPERATION}/${id}`);

/**
 * 编辑banner
 * @param {*} data
 * @returns
 */
export const editBanner = (data) => fetchApi(URL.BANNER_OPERATION, data, 'put', 'data');
/**
 * 启禁用
 * @param {*} data
 * @returns
 */
export const updateState = (data) => fetchApi(URL.BANNER_OPERATION + '/updateState', data, 'put', 'data');
/**
 * 删除banner
 * @param {*} id
 * @returns
 */
export const deleteBanner = (id) => fetchApi(`${URL.BANNER_OPERATION}/${id}`, null, 'delete');
